<template>
	<v-container fluid class="pa-0">
		<v-toolbar flat dense :style="stickyTop">
			<v-toolbar-title> Tickets </v-toolbar-title>
			<v-icon class="ml-4" @click.stop="getCashReceipts()">mdi-refresh</v-icon>
		</v-toolbar>
		<v-container>
			<v-row justify="center">
				<!-- KASTICKETS -->
				<v-col cols="12" md="10" lg="9">
					<v-data-table :items="cashReceipts" item-key="kastnr" :headers="headers" :loading="loading"
						hide-default-footer disable-sort disable-pagination fixed-header show-expand single-expand
						:expanded.sync="expandedItems" v-on:item-expanded="loadDetails">
						<template v-slot:expanded-item="{ headers }">
							<td :colspan="headers.length" class="pa-0">
								<v-container class="grey lighten-5 pa-8">
									<v-row><v-col cols="12">
											<v-sheet outlined>
												<v-data-table dense item-key="lijnnr" disable-sort hide-default-footer
													:items="products" :headers="detailHeaders" /></v-sheet> </v-col></v-row>
									<v-row class="mt-6">
										<v-col cols="4" offset="8">
											<v-text-field type="Number" v-model="amount" label="Totaal" hide-details
												outlined @input="dataModified = true" />
										</v-col>
										<v-col cols="4" offset="8">
											<v-text-field v-show="dataModified" v-model="productCode" label="Artikelnr"
												hide-details outlined />
										</v-col>
										<v-col cols="4" offset="8">
											<v-btn v-show="
																								dataModified && productCode
																							" text outlined block x-large @click="submitUpdateTicket()" :loading="loading"><v-icon color="green" large
													left class="mr-6">mdi-check</v-icon>Wijzigen</v-btn>
										</v-col>
									</v-row>
								</v-container>
							</td>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
	import caas from "@/services/caas";
	export default {
		name: "Receipts",
		data() {
			return {
				amount: 0,
				productCode: "",
				dataModified: false,
				loading: false,
				cashReceipts: [],
				expandedItems: [],
				cashReceipt: {},
				products: [],
			};
		},
		computed: {
			headers() {
				return [
					{ text: "Kasticket", value: "kastnr" },
					{ text: "Datum", value: "datum" },
					{ text: "Tijdstip", value: "uurstring" },
					{ text: "Totaal", value: "totaal", align: "end" },
					{ text: "Factuurnr", value: "faktuurnr" },
					{ text: "Klantnr", value: "klnr" },
					{ text: "", value: "data-table-expand" },
				];
			},
			detailHeaders() {
				return [
					{ text: "Artikelnr", value: "anr" },
					{ text: "Omschrijving", value: "omschrijving" },
					{ text: "Aantal", value: "aantal", align: "end" },
					{ text: "Ehp", value: "eup", align: "end" },
				];
			},
			stickyTop() {
				return {
					position: "sticky",
					top: this.$vuetify.application.top + "px",
					zIndex: 1,
				};
			},
		},
		methods: {
			getCashReceipts() {
				const sdoName = "order/objects/dKasth.r";
				let params = {
					action: "read",
					rowIdent: "",
					nbrOfRows: 999,
					setOpenQuery:
						"for each kasth where verwerkt >= 10 and verwerkt <= 20 no-lock indexed-reposition ",
					setQuerySort: "by kastnr ",
				};
				this.loading = true;
				return caas
					.sdo(sdoName, params)
					.then((response) => {
						if (response.data) {
							this.cashReceipts =
								response.data.data.RowObject;
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			loadDetails({ item }) {
				this.cashReceipt = item;
				this.amount = item.totaal;
				this.productCode = "";
				this.dataModified = false;
				const sdoName = "order/objects/dKasti.r";
				let params = {
					action: "read",
					rowIdent: "",
					nbrOfRows: 999,
					setOpenQuery:
						"for each kasti where dossiernr = " +
						this.cashReceipt.dossiernr +
						" and kastnr = " +
						this.cashReceipt.kastnr +
						" no-lock indexed-reposition ",
				};
				this.loading = true;
				return caas
					.sdo(sdoName, params)
					.then((response) => {
						if (response.data) {
							this.products = response.data.data.RowObject;
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			updateTicket() {
				this.loading = true;
				let params = {
					cashRegisterId: this.cashReceipt.kassanr,
					receiptId: this.cashReceipt.kastnr,
					productCode: this.productCode,
					amount: Number(this.amount),
				};
				return caas
					.rpc("updateCashReceipt", params)
					.then((response) => {
						if (response.data.error) throw response.data.error;
						this.$store.dispatch("session/showSuccess");
						this.expandedItems = [];
						this.getCashReceipts();
					})
					.catch((e) => {
						alert(e);
					})
					.finally(() => {
						this.loading = false;
					});
			},
			submitUpdateTicket() {
				const that = this;
				that.loading = true;
				window.setTimeout(function () {
					that.updateTicket();
				}, 1500);
			},
		},
		mounted() {
			this.getCashReceipts();
		},
	};
</script>

