import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VToolbar,{style:(_vm.stickyTop),attrs:{"flat":"","dense":""}},[_c(VToolbarTitle,[_vm._v(" Tickets ")]),_c(VIcon,{staticClass:"ml-4",on:{"click":function($event){$event.stopPropagation();return _vm.getCashReceipts()}}},[_vm._v("mdi-refresh")])],1),_c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"10","lg":"9"}},[_c(VDataTable,{attrs:{"items":_vm.cashReceipts,"item-key":"kastnr","headers":_vm.headers,"loading":_vm.loading,"hide-default-footer":"","disable-sort":"","disable-pagination":"","fixed-header":"","show-expand":"","single-expand":"","expanded":_vm.expandedItems},on:{"update:expanded":function($event){_vm.expandedItems=$event},"item-expanded":_vm.loadDetails},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c(VContainer,{staticClass:"grey lighten-5 pa-8"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VSheet,{attrs:{"outlined":""}},[_c(VDataTable,{attrs:{"dense":"","item-key":"lijnnr","disable-sort":"","hide-default-footer":"","items":_vm.products,"headers":_vm.detailHeaders}})],1)],1)],1),_c(VRow,{staticClass:"mt-6"},[_c(VCol,{attrs:{"cols":"4","offset":"8"}},[_c(VTextField,{attrs:{"type":"Number","label":"Totaal","hide-details":"","outlined":""},on:{"input":function($event){_vm.dataModified = true}},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1),_c(VCol,{attrs:{"cols":"4","offset":"8"}},[_c(VTextField,{directives:[{name:"show",rawName:"v-show",value:(_vm.dataModified),expression:"dataModified"}],attrs:{"label":"Artikelnr","hide-details":"","outlined":""},model:{value:(_vm.productCode),callback:function ($$v) {_vm.productCode=$$v},expression:"productCode"}})],1),_c(VCol,{attrs:{"cols":"4","offset":"8"}},[_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(
																							_vm.dataModified && _vm.productCode
																						),expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdataModified && productCode\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"}],attrs:{"text":"","outlined":"","block":"","x-large":"","loading":_vm.loading},on:{"click":function($event){return _vm.submitUpdateTicket()}}},[_c(VIcon,{staticClass:"mr-6",attrs:{"color":"green","large":"","left":""}},[_vm._v("mdi-check")]),_vm._v("Wijzigen")],1)],1)],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }